import React from "react";
import {Helmet} from 'react-helmet';
import './style.css';
import Layout from '../components/layout/layout';
import MainLogo from '../resources/images/mainLogo.png';
import BismillahLogo from '../resources/images/bismillah.svg';
export default function Home() {
  return(
    <Layout>
<div className='homeMain'>
<Helmet>
  <meta charSet='utf-8' />
    <meta name="google-site-verification" content="nfxZsnwGY2wmzTgQHIV6V7-RmrR_WvlquCZwYIrK0ZI" />
  <title>Ansarulmomineen| Home</title>
</Helmet>
<img className='bismillahLogo' src={BismillahLogo} alt='bsimillah' />
<section className='intro'>
<img className='mainLogo slideUp' src={MainLogo} alt='mainLogo' />
  <h1 className='slideUp' >AnsarulMomineen</h1>
  <h3 className='slideUp'>From eye sight to insight</h3>
  </section>
  <section>
  </section>
</div>
    </Layout>
  )
}
